import { autocomplete } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import { createElement, Fragment, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { SearchResponseAdapter } from "typesense-instantsearch-adapter/lib/SearchResponseAdapter";
import PropTypes from "prop-types";

import "../../styles/Autocomplete.css";
import { t } from "i18next";
import { SearchItem } from "./SearchItem";
import { useTranslation } from "react-i18next";
import { COLLECTION_NAME, search } from "../../utils/typesenseClient";

export function Autocomplete(options) {
    const { className, onNavigate, ...props } = options;
    const { i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    // const attribute = currentLanguage === "ar" ? "normalized_name_ar" : "name_en";
    const attribute = `name_${currentLanguage}`;

    // Refs for managing React 18 concurrent rendering with Autocomplete
    const containerRef = useRef(null);
    const panelRootRef = useRef(null);
    const rootRef = useRef(null);

    /**
     * Adapts the Typesense search response to match Algolia's hit format
     * @param {Object} result - Raw search result from Typesense
     * @returns {Array} Adapted hits array
     */
    const adaptSearchResponse = (result) =>
        new SearchResponseAdapter(
            result,
            { params: {} },
            { geoLocationField: "_geoloc" },
        ).adapt().hits;

    /**
     * Fetches and formats search results from Typesense
     * @param {string} query - Search query string
     * @returns {Array} Array of source objects for Autocomplete
     */
    const getTypesenseSources = async (query) => {
        const results = await search(query);

        return [
            {
                sourceId: COLLECTION_NAME,
                getItems: () => adaptSearchResponse(results),
                getItemUrl: ({ item }) => `${item[attribute]}`,
                getItemInputValue: ({ item }) => item[attribute],
                templates: {
                    item: ({ item, components }) => (
                        <SearchItem
                            hit={item}
                            components={components}
                            onItemClick={onNavigate}
                        />
                    ),
                    noResults: () => (
                        <div className="aa-EmptyStateContainer">
                            <span className="text-sm text-gray-500">
                                {t("no_results_found")}
                            </span>
                        </div>
                    ),
                },
            },
        ];
    };

    /**
     * Plugin configuration for recent searches functionality
     * Stores up to 5 recent searches in localStorage
     */
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
        key: "RECENT_SEARCH",
        limit: 5,
        transformSource({ source, onRemove }) {
            return {
                ...source,
                // Handles item selection for recent searches
                onSelect({ item }) {
                    props.onSelect?.(item.label);
                },
                templates: {
                    item({ item, components }) {
                        return (
                            <SearchItem
                                hit={{
                                    [attribute]: item.label,
                                }}
                                components={components}
                                onItemClick={() => props.onSelect?.(item.label)}
                                onRemove={() => onRemove(item.label)}
                                isRecent={true}
                            />
                        );
                    },
                },
            };
        },
    });

    /**
     * Initialize Autocomplete instance and handle cleanup
     * Uses React 18 compatible rendering approach with createRoot
     */
    useEffect(() => {
        if (!containerRef.current) return undefined;

        const search = autocomplete({
            container: containerRef.current,
            // Empty render function in renderer to comply with Autocomplete v1.6.0+ requirements
            renderer: { createElement, Fragment, render: () => {} },
            render({ children }, root) {
                if (!panelRootRef.current || rootRef.current !== root) {
                    rootRef.current = root;
                    panelRootRef.current?.unmount();
                    panelRootRef.current = createRoot(root);
                }
                panelRootRef.current.render(children);
            },
            translations: {
                clearButtonTitle: t("clear_button_title"),
                detachedCancelButtonText: t("cancel_button_title"),
                submitButtonTitle: t("submit_button_title"),
            },
            plugins: [recentSearchesPlugin],
            getSources: async ({ query }) =>
                query.length === 0 ? [] : await getTypesenseSources(query),
            ...props,
        });

        // Cleanup function to destroy autocomplete instance
        return () => search.destroy();
    }, [props, recentSearchesPlugin]);

    return <div ref={containerRef} className={className} />;
}

Autocomplete.propTypes = {
    className: PropTypes.string,
    onSelect: PropTypes.func,
    onNavigate: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    openOnFocus: PropTypes.bool,
    initialState: PropTypes.shape({
        query: PropTypes.string,
    }),
    navigator: PropTypes.shape({
        navigate: PropTypes.func,
    }),
};
