// PrivacyPolicyPage.js
import { useTranslation } from "react-i18next";
import SEO from "../components/common/SEO.jsx";
import { PropTypes } from "prop-types";
import { DOMAIN } from "../utils/constants.js";

// Common styles
const styles = {
    base: "text-body-font break-words",
    section: "py-3",
    title: "font-heading mb-2 text-2xl font-medium leading-8",
    subtitle:
        "font-heading mb-2 text-base font-medium leading-8 text-body-font",
    text: "break-words text-body-font",
    boldText: "break-words font-bold text-body-font",
    link: "text-primary-orange hover:underline transition-colors duration-200",
    list: "list-inside list-disc ps-4 space-y-2",
    listItem: "break-words text-body-font",
    container: "font-lato container mx-auto mb-8 p-6 text-base",
    header: {
        wrapper: "py-5 text-center",
        title: "font-heading my-10 text-center text-5xl font-medium text-body-font",
        subtitle: "text-gray-500 text-center",
    },
    contact: {
        text: "font-lato text-justify text-lg font-normal leading-relaxed text-body-font",
    },
};

const PolicySection = ({ title, children }) => (
    <section className={styles.section}>
        {title && <h3 className={`${styles.base} ${styles.title}`}>{title}</h3>}
        <div className="space-y-3">{children}</div>
    </section>
);
PolicySection.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

const ListItems = ({ items }) => (
    <ul className={styles.list}>
        {items.map((item, i) => (
            <li key={i} className={styles.base}>
                {item.title && <strong>{item.title}</strong>}{" "}
                {item.description || item.term}
            </li>
        ))}
    </ul>
);
ListItems.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            term: PropTypes.string,
        }),
    ),
};

const Text = ({ bold, children }) => (
    <p className={`${styles.base} ${bold ? "font-bold" : ""}`}>{children}</p>
);
Text.propTypes = {
    bold: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

const Link = ({ href, children }) => (
    <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary-orange hover:underline"
    >
        {children}
    </a>
);
Link.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    const tr = (key, options) => t(`privacyPolicy.${key}`, options);
    const trList = (key) => tr(key, { returnObjects: true });

    return (
        <div className={styles.container}>
            <SEO title={tr("seoTitle")} description={tr("seoDescription")} />

            <PolicySection className={styles.header.wrapper}>
                <h1 className={styles.header.title}>{tr("title")}</h1>
                <p className={styles.header.subtitle}>{tr("lastUpdated")}</p>
            </PolicySection>

            <PolicySection>
                <Text>
                    {tr("interpretation.title")}{" "}
                    <Link href={`https://${DOMAIN}`}>
                        {`https://${DOMAIN}`}
                    </Link>{" "}
                    {tr("interpretation.titletwo")}

                </Text>
                <Text>{tr("interpretation.interpretationTitle")}</Text>
            </PolicySection>

            <PolicySection title={tr("interpretation.definitionsTitle")}>
                <Text bold>{tr("interpretation.definitionsIntro")}</Text>
            </PolicySection>

            <PolicySection>
                <Text bold>{tr("interpretation.personalInformation")}</Text>
                <Text>{tr("interpretation.personalinformationdetails")}</Text>
                <ListItems
                    items={trList("interpretation.personalInformationList")}
                />
            </PolicySection>

            <PolicySection>
                <Text bold>{tr("interpretation.nonpersonalinformation")}</Text>
                <Text>{tr("interpretation.nonpersonalinformationdetails")}</Text>
                <ListItems
                    items={trList("interpretation.nonPersonalInformationList")}
                />
            </PolicySection>

            <PolicySection>
                <Text bold>{tr("interpretation.cookiestitle")}</Text>
                <Text>{tr("interpretation.cookiesInfo")}</Text>
                <Text>{tr("interpretation.cookiesInfotwo")}</Text>
            </PolicySection>

            <PolicySection title={tr("use.title")}>

                <Text>{tr("use.description")}</Text>
                <ListItems items={trList("use.purposes")} />
            </PolicySection>

            <PolicySection title={tr("share.title")}>
                <Text>{tr("share.description")}</Text>
                <ListItems items={trList("share.purposes")} />
            </PolicySection>

            <PolicySection title={tr("safety.title")}>
                <Text>{tr("safety.description")}</Text>
                <ListItems items={trList("safety.purposes")} />
                <Text>{tr("safety.end")}</Text>
            </PolicySection>

            <PolicySection title={tr("retention.title")}>
                <Text>{tr("retention.text")}</Text>
            </PolicySection>

            <PolicySection title={tr("rightschoices.title")}>
                <Text>{tr("rightschoices.description")}</Text>
                <ListItems items={trList("rightschoices.purposes")} />
            </PolicySection>

            <PolicySection title={tr("Links.title")}>
                <Text>{tr("Links.text")}</Text>
            </PolicySection>

            <PolicySection title={tr("children.title")}>
                <Text className="inline">
                    {tr("children.text")}
                    <Link href="mailto:contact@nexumind.com">
                        {tr("children.email")}
                    </Link>
                </Text>
            </PolicySection>

            <PolicySection title={tr("datatransfer.title")}>
                <Text>{tr("datatransfer.text")}</Text>
            </PolicySection>

            <PolicySection title={tr("changes.title")}>
                <Text>{tr("changes.text")}</Text>
            </PolicySection>

            <PolicySection title={tr("contact.title")}>
                <Text>{tr("contact.text")}</Text>
                <Text>
                    {tr("contact.email")}
                    <Link href="mailto:contact@nexumind.com">
                        {tr("contact.nexumind_email")}
                    </Link>
                </Text>
            </PolicySection>
        </div>
    );
};

export default PrivacyPolicyPage;
