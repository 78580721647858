import { useTranslation } from "react-i18next";
import SEO from "../components/common/SEO.jsx";
import PropTypes from "prop-types";
import { DOMAIN } from "../utils/constants.js";

// Page-specific components
const SectionHeader = ({ title, lastUpdated }) => (
    <section className="py-5 text-center">
        <h1 className="font-heading my-10 text-center text-5xl font-medium text-body-font">
            {title}
        </h1>
        <p className="text-gray-500">{lastUpdated}</p>
    </section>
);

const SectionContent = ({ title, content, children }) => (
    <div className="mb-6">
        <h3 className="font-heading mb-4 text-2xl font-medium leading-10 text-body-font">
            {title}
        </h3>
        {content && (
            <p className="break-words font-medium text-body-font">{content}</p>
        )}
        {children}
    </div>
);

const ListSection = ({ items }) => (
    <ul className="list-disc ms-6 font-medium text-body-font">
        {items.map((item, index) => (
            <li key={index}>{item}</li>
        ))}
    </ul>
);

const ContactInfoSection = ({ contactInfo }) => (
    <div className="font-medium text-body-font">
        <div className="flex items-center">
            <p className="mx-1">{contactInfo.email}</p>
            <a
                href={`mailto:${contactInfo.emailinfo}`}
                className="text-primary-orange no-underline hover:underline"
            >
                {contactInfo.emailinfo}
            </a>
        </div>
    </div>
);

// PropTypes
SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string.isRequired,
};

SectionContent.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    children: PropTypes.node,
};

ListSection.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ContactInfoSection.propTypes = {
    contactInfo: PropTypes.shape({
        email: PropTypes.string.isRequired,
        emailinfo: PropTypes.string.isRequired,
    }).isRequired,
};

const TermsAndConditionsPage = () => {
    const { t } = useTranslation();

    const pageMetadata = {
        title: t("terms_and_conditions.seoTitle"),
        description: t("terms_and_conditions.seoDescription"),
    };

    // Get section data
    const getSectionData = (index) => {
        const section = t(`terms_and_conditions.sections.${index}`, {
            returnObjects: true,
        });
        return section?.title ? section : null;
    };

    // Render section content
    const renderSectionContent = (index, section) => {
        const content = [];

        // Special case for section 4 (Trademarks)
        if (index === 4 && section.trademarks) {
            content.push(
                <div key="trademarks">
                    <h4 className="font-heading my-2 text-xl font-medium leading-8 text-body-font">
                        {section.trademarks.title}
                    </h4>
                    <p className="break-words font-medium text-body-font">
                        {section.trademarks.paragraph}
                    </p>
                </div>,
            );
        }

        // Lists handling
        if (section.prohibitedActions?.length > 0) {
            content.push(
                <ListSection
                    key="prohibited"
                    items={section.prohibitedActions}
                />,
            );
        }

        if (section.accountRules?.length > 0) {
            content.push(
                <ListSection key="rules" items={section.accountRules} />,
            );
        }

        if (section.disclaimerList?.length > 0) {
            content.push(
                <ListSection
                    key="disclaimerList"
                    items={section.disclaimerList}
                />,
            );
        }

        if (section.indemnificationReasons?.length > 0) {
            content.push(
                <ListSection
                    key="indemnification"
                    items={section.indemnificationReasons}
                />,
            );
        }

        if (section.liabilityExceptions?.length > 0) {
            content.push(
                <ListSection
                    key="liability"
                    items={section.liabilityExceptions}
                />,
            );
        }

        // Additional Info
        if (section.additionalInfo) {
            content.push(
                <p
                    key="additional"
                    className="break-words font-medium text-body-font"
                >
                    {section.additionalInfo}
                </p>,
            );
        }

        if (section.additionalInfo2) {
            content.push(
                <p
                    key="additional2"
                    className="break-words font-medium text-body-font"
                >
                    {section.additionalInfo2}
                </p>,
            );
        }

        // Contact Info
        if (section.contactInfo) {
            content.push(
                <ContactInfoSection
                    key="contact"
                    contactInfo={section.contactInfo}
                />,
            );
        }

        return content;
    };

    return (
        <div className="font-lato container mx-auto mb-8 p-6 text-base">
            <SEO {...pageMetadata} />

            <SectionHeader
                title={t("terms_and_conditions.title")}
                lastUpdated={t("terms_and_conditions.lastUpdated")}
            />

            <section className="py-2 text-body-font">
                <h3 className="font-heading mb-2 text-base font-medium leading-8">
                    {t("terms_and_conditions.intro.paragraph")}
                    <a
                        href={`https://${DOMAIN}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mx-1 text-primary-orange underline"
                    >
                        {`https://${DOMAIN}`}
                    </a>
                    {t("terms_and_conditions.intro.paragraphtwo")}
                </h3>

                {Array.from({ length: 15 }).map((_, index) => {
                    const section = getSectionData(index);
                    if (!section) return null;

                    return (
                        <SectionContent
                            key={`section-${index}`}
                            title={section.title}
                            content={section.content}
                        >
                            {renderSectionContent(index, section)}
                        </SectionContent>
                    );
                })}
            </section>
        </div>
    );
};

export default TermsAndConditionsPage;
