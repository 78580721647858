import PropTypes from "prop-types";
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// custom style
import "../../styles/custom-swiper.css";
import { BASE_OFFERS_URL } from "../../utils/constants";

function HomeSlider({ offers, language }) {
    return (
        <div className="aspect-[18/8] w-full">
            <Swiper
                key={language} // Changing the key forces re-render
                // install Swiper modules
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={50}
                navigation
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                loop={true}
                pagination={{ clickable: true, dynamicBullets: true }}
                lazyPreloadPrevNext={1}
                lazyPreloaderClass="swiper-lazy-preloader"
                className="h-full w-full"
            >
                {offers.map((offer, index) => (
                    <SwiperSlide key={index} className="relative h-full w-full">
                        <a
                            href={offer.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block h-full w-full"
                        >
                            <img
                                src={BASE_OFFERS_URL + offer.photo}
                                alt={`${offer.store.name} offers`}
                                className="h-full w-full rounded object-cover shadow-lg"
                                // loading={index === 0 ? "eager" : "lazy"}
                                loading="lazy"
                                width={1920}
                                height={900}
                            />
                            <div className="swiper-lazy-preloader"></div>
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

HomeSlider.propTypes = {
    language: PropTypes.string,
    offers: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            photo: PropTypes.string.isRequired,
            store: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }),
    ).isRequired,
};

export default HomeSlider;
