import { useQuery } from "react-query";
import {
    fetchCategories,
    fetchStores,
    fetchOffers,
    fetchBrands,
    fetchPriceRange,
    fetchProductsCompare,
} from "../utils/api";

export const useCategories = (state) => {
    return useQuery(["categories", state], () =>
        fetchCategories(state?.selectedStores || []),
    );
};

export const useStores = () => {
    return useQuery("stores", fetchStores);
};

export const useOffers = () => {
    return useQuery("offers", fetchOffers);
};

export const useBrands = (state) => {
    return useQuery(["brands", state], () => fetchBrands(state));
};

export const usePriceRange = (state) => {
    return useQuery(["priceRange", state], () => fetchPriceRange(state));
};

export const useProductsCompare = (productsId) => {
    return useQuery(
        ["productsCompare", productsId],
        () => fetchProductsCompare(productsId),
        {
            enabled: !!productsId?.length, // Only run query if productsId array has items
        },
    );
};
