import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useFilter } from "./contexts/FilterContext";
import Routing from "./routes/Routing";
import ResetSearchOnHome from "./components/common/ResetSearchOnHome.jsx";
import Header from "./components/common/Header.jsx";
import Footer from "./components/common/Footer.jsx";
import FloatingChatButton from "./components/ai/ChatBot.jsx";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/common/ScrollToTop.jsx";
import { CompareProvider } from "./contexts/CompareContext.jsx";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const queryClient = new QueryClient();

const App = () => {
    const { i18n } = useTranslation();
    const { dispatch } = useFilter();

    document.body.dir = i18n.dir();

    useEffect(() => {
        dispatch({ type: "SET_LANGUAGE", payload: i18n.resolvedLanguage });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <HelmetProvider>
            <CompareProvider>
                <BrowserRouter>
                <ScrollToTop />
                    <Header />
                    <ResetSearchOnHome />
                    <Routing />
                    <FloatingChatButton/>
                    <Footer />
                </BrowserRouter>
                </CompareProvider>
            </HelmetProvider>
            <ToastContainer />
        </QueryClientProvider>
    );
};

export default App;
