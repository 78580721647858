import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductsListPage from "../pages/ProductsListPage";
import HomePage from "../pages/HomePage";
import Loading from "../components/common/Loading.jsx";
import AboutUsPage from "../pages/AboutUsPage";
import StoresPage from "../pages/StoresPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import NotFoundPage from "../pages/NotFoundPage.jsx";
import CompareListPage from "../pages/CompareListPage.jsx";
import CompareDetailsPage from "../pages/CompareDetailsPage.jsx";
// import ProductDetails from "../pages/ProductDetails";
const LazyProductDetails = React.lazy(() => import("../pages/ProductDetailsPage.jsx"));

function Routing() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/product" element={<ProductsListPage />} />
            <Route
                // path="/product/:product_id/:id"
                path="/product/:params"
                element={
                    <React.Suspense fallback={<Loading />}>
                        <LazyProductDetails />
                    </React.Suspense>
                }
            />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/stores" element={<StoresPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            <Route path="/compare" element={<CompareListPage />} />
            <Route path="/compare/:categoryId" element={<CompareDetailsPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default Routing;
