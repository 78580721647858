export const capitalizeFirstLetter = (text) => {
    if (typeof text !== "string" || text.length === 0) {
        return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
};

// Optimized createSlug function for better performance
export const createSlug = (text) => {
    if (!text) return "";

    // Use a single regex operation instead of multiple replacements
    return text
        .toLowerCase()
        .replace(/[^\w\s-]/g, "") // Remove non-word chars (except spaces and hyphens)
        .trim() // Remove whitespace from both ends
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/-{2,}/g, "-") // Replace multiple hyphens with single hyphen
};