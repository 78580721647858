import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import "./styles/tailwind.css";
import App from "./App.jsx";
import { BASE_URL } from "./utils/constants";
import "./i18n";
import { FilterProvider } from "./contexts/FilterContext";

axios.defaults.baseURL = BASE_URL;

// only for production
if (import.meta.env.VITE_ENVIRONMENT === "production") {
    import("./instrument");
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <FilterProvider>
                <App />
            </FilterProvider>
        </Suspense>
    </React.StrictMode>,
);
