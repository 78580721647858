/**
 * Checks if a price range is different from the default range
 * @param {[number, number]} priceRange - The price range to compare [min, max]
 * @param {[number, number]} defaultRange - The default price range to compare against
 * @returns {boolean} - Returns true if ranges are different, false if they're the same
 */
export const isPriceRangeDifferent = (priceRange, defaultRange) => {
    
    if (!priceRange || !Array.isArray(priceRange)) return false;
    return (
        priceRange[0] !== defaultRange[0] || priceRange[1] !== defaultRange[1]
    );
};
