import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Filters from "../components/productsList/Filters.jsx";
import ProductList from "../components/productsList/ProductList.jsx";
import ToggleFilterButton from "../components/productsList/ToggleFilterButton.jsx";
import SEO from "../components/common/SEO.jsx";
import { useFilter } from "../contexts/FilterContext.jsx";
import { useLocation } from "react-router-dom";
const ProductsListPage = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useFilter();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("q");

    // add a search query to the state
    useEffect(() => {
        if (searchQuery) {
            dispatch({ type: "SET_SEARCH", payload: searchQuery });
        }
    }, [searchQuery, dispatch]);

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isSortVisible, setIsSortVisible] = useState(false);

    const toggleFilterVisibility = useCallback(() => {
        if (isSortVisible) {
            setIsSortVisible(false);
        }
        setIsFilterVisible((prev) => !prev);
    }, [isSortVisible]);

    const toggleSortVisibility = useCallback(() => {
        if (isFilterVisible) {
            setIsFilterVisible(false);
        }
        setIsSortVisible((prev) => !prev);
    }, [isFilterVisible]);

    // SEO metadata
    const page = {
        title: t("products_list_page.title"),
        description: t("products_list_page.description"),
    };

    return (
        <>
            <ToggleFilterButton
                onSortClick={toggleSortVisibility}
                onFilterClick={toggleFilterVisibility}
                activeButton={
                    isFilterVisible ? "filter" : isSortVisible ? "sort" : null
                }
            />
            <div className="mx-6 sm:mx-4">
                <SEO title={page.title} description={page.description} />
                <div
                    // className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-12"
                    className="mt-[1px] grid grid-cols-1 gap-4 lg:grid-cols-12"
                >
                    <Filters
                        isVisible={isFilterVisible}
                        onFilterClick={toggleFilterVisibility}
                        filterState={state}
                        filterDispatch={dispatch}
                    />
                    <ProductList
                        isSortVisible={isSortVisible}
                        onSortClick={toggleSortVisibility}
                        filterState={state}
                        filterDispatch={dispatch}
                    />
                </div>
            </div>
        </>
    );
};

export default ProductsListPage;
