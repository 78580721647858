// works for react-create-app project
// export const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";
// works for vite app
// export const BASE_URL = import.meta.env.VITE_BASE_URL || "https://server.rakhys.com/";

export const DOMAIN =
    import.meta.env.VITE_DOMAIN ||
    window.location.host.split(".").slice(-2).join(".");

export const BASE_URL =
    import.meta.env.VITE_BASE_URL ||
    `https://${import.meta.env.VITE_SUBDOMAIN}.${DOMAIN}`;

export const CHAT_API_BASE_URL =
    import.meta.env.VITE_FLASK_CHAT_BASE_URL ||
    `https://${import.meta.env.VITE_CHAT_SUBDOMAIN}.${DOMAIN}`;

export const BASE_URL_IMAGE = `${BASE_URL}/public/images/`;
export const BASE_OFFERS_URL = `${BASE_URL_IMAGE}/offers/`;
export const BASE_CATEGORIES_URL = `${BASE_URL_IMAGE}/categories/`;
export const BASE_STORES_URL = `${BASE_URL_IMAGE}/stores/`;

export const PRODUCTS_LIMIT = 12;

export const SORT_OPTIONS = {
    RELEVANCE: "relevance",
    RECENTLY: "updatedAt", // DESC
    DEALS: "discount", // DESC
    PRICE: "price", // ASC
};

export const SORT_ORDER = {
    ASC: "asc",
    DESC: "desc",
};

// typesense
export const TYPESENSE_URL = `${import.meta.env.VITE_TYPESENSE_PROTOCOL}://${import.meta.env.VITE_TYPESENSE_HOST}:${import.meta.env.VITE_TYPESENSE_PORT}`;
export const TYPESENSE_API_KEY = import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY;
export const TYPESENSE_COLLECTION = import.meta.env.VITE_TYPESENSE_COLLECTION;

export const MAX_SELECTABLE_PRODUCTS = 4;

// for price slider
export const PRICE_SLIDER_STEP = 50;

export const PRICE_SLIDER_STYLES = {
    track: { backgroundColor: "#38a2b3" },
    rail: { backgroundColor: "#dfe4ea" },
    active: { backgroundColor: "#38a2b3" },
    thumb: {
        borderColor: "#38a2b3",
        backgroundColor: "#38a2b3",
        opacity: 0.7,
    },
    handle: {
        borderColor: "#38a2b3",
        backgroundColor: "#38a2b3",
        opacity: 0.7,
    },
};

export const DEFAULT_PRICE_RANGE = [0, 100000];
