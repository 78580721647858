import React from "react";
import { useTranslation } from "react-i18next";
import { useCompare } from "../contexts/CompareContext";
import { useParams } from "react-router-dom";
import { BASE_STORES_URL } from "../utils/constants";
import { useProductsCompare } from "../hooks/useFetch";
import Loading from "../components/common/Loading";
import LoadingError from "../components/common/LoadingError";
import { getLocalizedData } from "../utils/getLocalizedData";
import NoProductsFound from "../components/productsList/NoProductsFound";
import SEO from "../components/common/SEO";
import { SaudiRiyal } from "../components/productsList/SaudiRiyal";

export default function CompareDetailsPage() {
    const { t, i18n } = useTranslation();
    const { toggleCompare, state } = useCompare();

    const language = i18n.resolvedLanguage;
    const { categoryId } = useParams();

    // Filter products by category and selection
    const filteredProducts =
        state.categorizedProducts?.[categoryId]?.filter(
            (product) => product.isSelected,
        ) || [];

    // Map filtered products to their IDs
    const productsId = filteredProducts.map((product) => product.product_id);

    const {
        data: productsData,
        isLoading,
        error,
    } = useProductsCompare(productsId);

    // Check for no selected products
    if (filteredProducts.length === 0) {
        return <NoProductsFound message={t("no_selected_products")} />;
    }

    if (isLoading) return <Loading />;
    if (error) return <LoadingError errorMessage={error.message} />;

    // Attributes to display
    const attributes = [
        { key: "name", type: "text" },
        { key: "price", type: "price" },
        { key: "brand", type: "object", subKey: "name" },

        { key: "RAM", type: "specification" },
        { key: "Internal Memory", type: "specification" },
        { key: "Color", type: "specification" },
        { key: "Battery Capacity", type: "specification" },
        { key: "Screen Size", type: "specification" },
        { key: "Processor", type: "specification" },
    ];

    // Ensure safe access to product data
    const products = getLocalizedData(productsData, language) || [];

    const productsLength = products.length;

    if (productsLength < 2) {
        return <NoProductsFound message={t("select_at_least_two_products")} />;
    }

    const page = {
        seoTitle: t("compare_list_page.seoTitle"),
        seoDescription: t("compare_list_page.seoDescription"),
    };

    return (
        <div className="container mx-auto max-w-[1200px] px-2 py-4 sm:px-4 sm:py-8">
            <SEO title={page.seoTitle} description={page.seoDescription} />
            <h1 className="font-heading my-4 mb-6 text-center text-2xl font-medium text-body-font sm:my-10 sm:mb-14 sm:text-4xl">
                {t("comparing")}{" "}
                {filteredProducts[0][`category_name_${language}`]}
            </h1>

            <div className="-mx-1 overflow-x-auto sm:mx-0">
                <div className="px-2 sm:px-0">
                    <table
                        id="compare-table"
                        className="w-full border-collapse"
                    >
                        <thead className="rounded-lg border border-neutral-400/50">
                            <tr>
                                {products.map((product) => (
                                    <th
                                        key={product.id}
                                        className="relative flex-1 p-2"
                                    >
                                        <div className="mb-4 flex flex-col items-center justify-center">
                                            <img
                                                src={product.photo_url}
                                                alt={product[`name`]}
                                                loading="lazy"
                                                className="h-24 w-24 object-contain sm:h-32 sm:w-32"
                                            />

                                            {/* close button */}
                                            <div className="absolute right-0 top-0">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        toggleCompare({
                                                            ...product,
                                                            product_id:
                                                                product.id,
                                                            category_id:
                                                                categoryId,
                                                        })
                                                    }
                                                    disabled={
                                                        productsLength <= 2
                                                    }
                                                    className={`${
                                                        productsLength <= 2
                                                            ? "cursor-not-allowed text-gray-300"
                                                            : "text-gray-500 hover:text-gray-700"
                                                    }`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="h-6 w-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M6 18 18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>

                                            {/* store image */}
                                            <img
                                                src={
                                                    BASE_STORES_URL +
                                                    product.product_prices[0]
                                                        .store.logo
                                                }
                                                alt={
                                                    product.product_prices[0]
                                                        .store.name
                                                }
                                                className="absolute bottom-3 left-3 h-6 w-12 rounded bg-white object-contain shadow sm:h-7 sm:w-14"
                                            />
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {attributes.map((attr) => (
                                <React.Fragment key={attr.key}>
                                    <tr className="border border-neutral-400/50 bg-stone-50 text-center shadow-inner">
                                        <th
                                            colSpan={productsLength}
                                            className="font-heading px-3 py-2 text-center text-lg font-semibold leading-relaxed text-body-font sm:px-5 sm:py-2.5 sm:text-xl"
                                        >
                                            {t(attr.key)}
                                        </th>
                                    </tr>
                                    <tr className="border border-neutral-400/50 bg-white py-2 text-center shadow-inner sm:py-2.5">
                                        {productsData.map((product) => (
                                            <td
                                                key={product.id}
                                                className="font-lato border-x border-neutral-400/50 p-1.5 text-sm font-normal leading-normal text-body-font sm:p-2 sm:text-base"
                                            >
                                                {renderAttributeValue(
                                                    product,
                                                    attr,
                                                    language,
                                                    t,
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

function renderAttributeValue(product, attr, language, t) {
    switch (attr.type) {
        case "text":
            return product[`${attr.key}_${language}`] || t("not_available");
        case "price": {
            const price = product.product_prices[0];
            return price ? (
                <div>
                    <p
                        className="flex items-center justify-center text-body-font"
                        dir="ltr"
                        title={t("saudi_riyal")}
                    >
                        <SaudiRiyal className="mx-1 inline-block h-4 w-4" />
                        <span className="font-semibold">{price.price}</span>
                    </p>

                    {price.discount > 0 && (
                        <>
                            {/* was price */}
                            <p
                                className="flex items-center justify-center text-sm text-gray-500 line-through"
                                dir="ltr"
                                title={t("saudi_riyal")}
                            >
                                <SaudiRiyal
                                    color="#6b7280"
                                    className="mx-1 inline-block h-3 w-3"
                                />
                                <span>{price.was_price}</span>
                            </p>

                            {/* discount */}
                            <p className="text-sm text-green-600">
                                -{price.discount}%
                            </p>
                        </>
                    )}
                </div>
            ) : (
                t("not_available")
            );
        }
        case "object":
            return (
                product[attr.key][`${attr.subKey}_${language}`] ||
                t("not_available")
            );
        case "specification": {
            const spec = product.product_specifications.find(
                (s) => s[`specification_key_${language}`] === t(attr.key),
            );
            return spec
                ? spec[`specification_value_${language}`]
                : t("not_available");
        }
        default:
            return "-";
    }
}
