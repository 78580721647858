import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFilter } from "../../contexts/FilterContext.jsx";
import PropTypes from "prop-types";
import { Autocomplete } from "./Autocomplete.jsx";
import { createSlug } from "../../utils/stringUtils.js";

const SearchBar = ({ additionalClass }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const { state, dispatch } = useFilter();
    const navigate = useNavigate();

    const handleSubmit = useCallback(
        (searchQuery) => {
            const trimmedValue = searchQuery?.trim() || "";

            // if the search query is not the same as the current search query, update the search query
            if (trimmedValue !== state.search) {
                dispatch({ type: "SET_SEARCH", payload: trimmedValue });
            }
            // reset the filter and navigate to the search page
            dispatch({ type: "RESET_FILTERS" });
            navigate(`/product?q=${encodeURIComponent(trimmedValue)}`);
        },
        [dispatch, navigate, state.search],
    );

    const handleNavigate = useCallback(
        (item) => {
            // navigate to the product page
            navigate(`/product/${createSlug(item.name_en)}-${item.id}-${item.product_id}`);
            // const attribute = currentLanguage === "ar" ? "normalized_name_ar" : "name_en";
            const attribute = `name_${currentLanguage}`;

            const productName = item[attribute]?.trim() || "";
            if (productName !== state.search) {
                dispatch({ type: "SET_SEARCH", payload: productName });
            }
        },
        [navigate, dispatch, state.search, currentLanguage],
    );

    return (
        <Autocomplete
            placeholder={t("search_hint")}
            openOnFocus={true}
            onSubmit={({ state: autocompleteState }) =>
                handleSubmit(autocompleteState?.query)
            }
            onSelect={handleSubmit}
            onNavigate={handleNavigate}
            initialState={{ query: state.search }}
            className={`${additionalClass} font-lato text-base font-normal leading-normal text-secondary-font`}
            navigator={{
                navigate: ({ item }) => handleNavigate(item),
            }}
        />
    );
};

export default SearchBar;

SearchBar.propTypes = {
    additionalClass: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
};
