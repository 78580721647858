import Typesense from "typesense";
import { normalizeString } from "./normalizeString.js";
export const COLLECTION_NAME = import.meta.env.VITE_TYPESENSE_COLLECTION;
const TYPESENSE_URL = import.meta.env.VITE_TYPESENSE_NODE_URL;
const TYPESENSE_SEARCH_API_KEY = import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY;

const TYPESENSE_SERVER_CONFIG = {
    apiKey: TYPESENSE_SEARCH_API_KEY,
    nodes: [{ url: TYPESENSE_URL }],
    connectionTimeoutSeconds: 2,
    numRetries: 8,
};

export const typesenseClient = new Typesense.Client(TYPESENSE_SERVER_CONFIG);

export const search = async (query) => {
    const results = await typesenseClient
        .collections(COLLECTION_NAME)
        .documents()
        .search({
            q: normalizeString(query),
            query_by: "name_en,normalized_name_ar",
            query_by_weights: "5,5",
            include_fields: "name_en,name_ar,product_id,id",
            num_typos: 2,
            per_page: 5,
            sort_by: "_text_match:desc,popularity:desc",
            highlight_start_tag: "__aa-highlight__", // <===== Customize highlight tags, or "<b>", // or <b>
            highlight_end_tag: "__/aa-highlight__", // <===== Customize highlight tags, or "</b>", // or </b>
            highlight_full_fields: "name_en,normalized_name_ar", // <===== Needed for Highlight component
            enable_analytics: false
        });

    return results;
};

// get similar products by product id using vector search
export const getSimilarProducts = async (productId) => {
    const results = await typesenseClient
        .collections(COLLECTION_NAME)
        .documents()
        .search({
            q: "*",
            per_page: 4,
            vector_query: `embedding:([], id: ${productId})`,
            exclude_fields: "embedding",
        });

    return results.hits.map((hit) => hit.document);
};

